import React from "react"
import { graphql, Link } from "gatsby"
import MainPage from "./MainPage"
import { IOffice, IPerson, ITestimonial } from "@organic-return/foundation-react/src/lib/Contact"
import { IListing } from "@organic-return/foundation-react/src/lib"
import { AgentLayoutListingHeader } from "@organic-return/foundation-react/src/components/Contact/AgentLayoutListingHeader"
import { EContactFormLayout } from "@organic-return/foundation-react/src/components/Forms"
import { useSiteContext } from "./components/common/SiteContext"
import { IAgentLayoutProps } from "@organic-return/foundation-react/src/components/Contact/util"
import axios from "axios"
import { getRecaptchaToken, getSegmentAnonymousId, registerSegmentActivity } from "./lib/utils"
import truncate from "truncate-html"

export const query = graphql`
  query($nodeID: String!) {
    person(id: { eq: $nodeID }) {
      bio
      company
      email
      first
      fullName
      last
      mlsID
      officeName
      originID
      phone
      phoneMobile
      phoneOffice
      photos {
        url
      }
      social {
        channel
        url
      }
      workTitle      
      listings {
        id
        baths
        address {
          city
          county
          postal
          text          
        }
        beds        
        mlsID
        price
        sqft        
        defaultImage {          
          url
        }
        flags
        path
      }
      closedListings {
        id
        baths
        address {
          city
          county
          postal
          text          
        }
        beds        
        mlsID
        price
        sqft        
        defaultImage {          
          url
        }        
        path
      }      
      testimonials {
        text
        attribution
      }
      personalWebsite
    }
    configuration: agilitySiteConfiguration {
      customFields {
        agentContactBGImage {
          url
        }
        agentContactCardBGImage {
          url
        }
      }
    }
  }
`
const PersonPage = ({ data, location }: any) => {
  const siteContext = useSiteContext()

  const Layout = siteContext.agentPageLayout ?? AgentLayoutListingHeader

  data.person.office = data.person.office ?? [];
  data.person.listings = data.person.listings ?? [];

  let person = data.person as IPerson & { listings: IListing[] }
  person.listings &&
    person.listings.sort((l1: any, l2: any) =>
      l1.price > l2.price ? -1 : l2.price > l1.price ? 1 : 0
    )

  let office = data.person.office as IOffice[]
  let testimonial = data.person.testimonials as ITestimonial[]

  let personPageContext: Partial<IAgentLayoutProps> = {
    ...siteContext.agentLayoutProps,
    contactCardProps: {
      className: "max-w-screen-xl mx-auto",
      ...siteContext.agentLayoutProps?.contactCardProps,
      offices: office,
    },
    testimonialsProps: {
      ...siteContext.agentLayoutProps?.testimonialsProps,
      testimonials: testimonial,
    },
    tabsListingCardVitalsLayout: {
      ...siteContext.globalListingCardProps,
      ...siteContext.agentLayoutProps?.tabsListingCardVitalsLayout,
    }
  }

  const agentRequestInformationClick = async (formInput: any) => {
    const recaptchaToken = await getRecaptchaToken("about_agent");
    await axios({
      url: process.env.LEAD_CREATE_API_ENDPOINT,
      method: "POST",
      data: {
        recaptchaToken,
        formValues: formInput,
        propertyInfo: {
          createdFrom: "About Agent",
          listingAgentMlsId: data.person.mlsID.replace("MFR", "")
        },
        segmentAnonymousId: getSegmentAnonymousId(),
      },
    })
    registerSegmentActivity(`Agent Request Information Form Submission`, formInput)
  }

  personPageContext!.contactCardProps!.formProps = {
    ...personPageContext!.contactCardProps!.formProps,
    clickHandler: agentRequestInformationClick
  };

  if (personPageContext!.requestInfoFormProps) {
    personPageContext!.requestInfoFormProps!.clickHandler = agentRequestInformationClick;
  }

  if (personPageContext!.contactUsFormProps) {
    personPageContext!.contactUsFormProps!.clickHandler = agentRequestInformationClick;
  }

  let activeAgentListings = person.listings;
  let soldAgentListings = data.person.closedListings ?? []

  var pageTitle = `${person.fullName}${person.workTitle ? ` - ${person.workTitle}` : ""}`
  var pageDescripton = truncate(person?.bio ?? "", 297, { stripTags: true });

  return (
    <MainPage
      seoProps={{
        title: pageTitle,
        keywords: person.category?.join(", ") || person.workTitle,
        description: pageDescripton,
        location: location,
      }}
      headerProps={{ gradient: person.listings && person.listings.length > 0, ...siteContext.headerProps }}
      className="person-page"
    >
      <Layout
        agent={person}
        listings={{ active: activeAgentListings, sold: soldAgentListings }}
        showRequestInfoForm={true}
        className={`${person.listings.length > 0 ? "" : "md:mt-28"}`}
        contactCardBG={data.configuration.customFields.agentContactCardBGImage?.url && {
          url: data.configuration.customFields.agentContactCardBGImage?.url,
        }}
        requestInfoFormBG={data.configuration.customFields.agentContactBGImage?.url && {
          url: data.configuration.customFields.agentContactBGImage?.url,
        }}
        requestInfoFormProps={{
          classNames: `max-w-screen-xl mx-auto `,
          layout: EContactFormLayout.column3,
          title: "Request Information",
          inputTextAreaLabel: "Add message here...",
          clickHandler: agentRequestInformationClick
        }}
        linkFunc={props => {
          return (
            <Link
              to={props.href ?? ""}
              className={`hide-disabled text-inherit ${props.className}`}
              key={props.key}
              title={props.title}
            >
              {props.children}
            </Link>
          )
        }}
        linkImage={true}
        {...personPageContext}
      />
    </MainPage>
  )
}

export default PersonPage
